import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDuplicateEmailsError } from "hooks/useIsDuplicateEmail";
const LoaderButton = ({ children, isLoading, disabled, ...rest }: any) => {
  const isDuplicateEmail = useDuplicateEmailsError();
  return (
    <Button {...rest} disabled={isDuplicateEmail || disabled}>
      {children}
      {isLoading && (
        <CircularProgress size={20} style={{ marginLeft: "10px" }} />
      )}
    </Button>
  );
};

export default LoaderButton;
