import React, { useState } from "react";
import * as firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { db } from "../firebase";

type ModalData = {
  bodyTextOne: string;
  bodyTextTwo?: string;
  heading: string;
  inMaintenance: boolean;
};
export const MaintenanceModal = () => {
  const [
    maintenanceModalError,
    setMaintenanceModalError,
  ] = useState<firebase.firestore.FirestoreError | null>(null);
  const [maintenanceModalData, setMaintenanceModalData] = useState<
    ModalData | undefined
  >(undefined);

  React.useEffect(() => {
    db.collection("settings")
      .doc("maintenance")
      .onSnapshot(
        (doc) => {
          const data = doc.data();
          if (data?.inMaintenance !== maintenanceModalData?.inMaintenance)
            setMaintenanceModalData(data as ModalData);
        },
        (error) => {
          console.log("an error occurred");
          setMaintenanceModalError(error);
        }
      );
  }, [maintenanceModalData]);

  const [showNotice, setShowNotice] = useState(false);
  React.useEffect(() => {
    if (!!maintenanceModalData)
      setShowNotice(maintenanceModalData.inMaintenance);
  }, [maintenanceModalData]);

  return showNotice ? (
    <Dialog open={true} style={{ minHeight: "150px" }}>
      <DialogTitle id="title" style={{ background: "#00683A", color: "white" }}>
        {maintenanceModalData?.heading}
      </DialogTitle>
      <DialogContent
        style={{
          minHeight: "150px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <DialogContentText
          style={{
            color: "#333333",
            fontSize: "20px",
          }}
        >
          <p>{maintenanceModalData?.bodyTextOne}</p>
          {!!maintenanceModalData?.bodyTextTwo
            ? maintenanceModalData.bodyTextTwo
            : null}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  ) : !maintenanceModalError ? null : null;
};
