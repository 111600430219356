import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

import LogoBanner from "./LogoBanner";

const headerStyles = makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
    background: "#E6EDEB",
    marginBottom: theme.spacing(5)
  }
}));

const Header: React.FC = () => {
  const { header } = headerStyles();
  return (
    <header className={header}>
      <LogoBanner />
      <Container maxWidth="md">
        <Typography variant="h1">Online Colleague Discount</Typography>
        <Typography variant="h2">
          Let's get you set up with your colleague discount.
        </Typography>

        <Typography variant="h2" gutterBottom>
          All colleagues registering themselves and / or a Family or Friend
          member for online discount must have an active online groceries
          (customer) account.
        </Typography>
      </Container>
    </header>
  );
};

export default Header;
