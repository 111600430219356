import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Icon from "../assets/images/morrison_logo.svg";

const logoStyles = makeStyles(theme => ({
  logo: {
    padding: theme.spacing(1.5),
    paddingRight: 0,
    paddingLeft: 0,
    background: "#00683A",
    marginBottom: theme.spacing(4),
    cursor: "pointer"
  }
}));

const LogoBanner: React.FC = () => {
  const history = useHistory();
  const { logo } = logoStyles();

  const routeToHome = () => history.push("/");

  return (
    <section className={logo}>
      <Container maxWidth="md">
        <img src={Icon} alt="basket" onClick={routeToHome} data-testid="logo" />
      </Container>
    </section>
  );
};

export default LogoBanner;
