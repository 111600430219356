import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Questions from "components/Questions";
import CheckCircleIcon from "@material-ui/icons/CheckCircleSharp";
import AppState from "containers/AppState";

const successStyles = makeStyles((theme) => ({
  title: {
    display: "inline-block",
    marginLeft: "0.75rem",
    verticalAlign: "middle",
  },
}));

const Success: React.FC = () => {
  const { setSubmissionSuccess } = AppState.useContainer();
  const { title } = successStyles();

  useEffect(
    () => () => {
      setSubmissionSuccess(false);
    },
    [setSubmissionSuccess]
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <CheckCircleIcon color="primary" />
          <Typography variant="h3" className={title}>
            We've got your details
          </Typography>
          <Typography variant="body1">
            Your colleague discount will be applied to your account within the
            next 48 hours, if not sooner. The discount will be applied to your
            online orders when you are charged and will appear on your order
            receipt.
          </Typography>
        </Grid>
      </Grid>
      <Questions />
    </>
  );
};

export default Success;
