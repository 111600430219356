import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

const questionsStyles = makeStyles(theme => ({
  questions: {
    padding: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: theme.spacing(4),
    fontWeight: 600,
    fontSize: "1.125rem"
  },
  link: {
    cursor: "pointer"
  }
}));

const Questions: React.FC = () => {
  const { questions, link } = questionsStyles();
  const history = useHistory();
  return (
    <Grid item xs={12} className={questions}>
      <Typography variant="h4">Questions?</Typography>
      <Link
        data-testid="faqs-link"
        className={link}
        onClick={() => history.push("/faqs")}
        color="primary"
      >
        View our FAQs here
      </Link>
      <br />
      or Contact People Services{" "}
      <Link href="mailto:PeopleServices@morrisonsplc.co.uk" color="primary">
        PeopleServices@morrisonsplc.co.uk
      </Link>
    </Grid>
  );
};

export default Questions;
