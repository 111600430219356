import { useEffect } from "react";
import { useFormikContext } from "formik";
import AppState from "containers/AppState";
import { isEmpty } from "lodash";

const PersistFormikState: React.FC<{ isSuccess: boolean }> = ({
  isSuccess
}) => {
  const {
    values,
    errors,
    touched,
    setValues,
    setErrors,
    setTouched
  }: any = useFormikContext();
  const {
    formikValues,
    formikErrors,
    formikTouched,
    setFormikTouched,
    setFormikValues,
    setFormikErrors,
    resetFormikState
  } = AppState.useContainer();

  useEffect(() => {
    setValues(formikValues);
    setErrors(formikErrors);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTouched(formikTouched);
  }, [formikValues, formikTouched, setTouched]);

  useEffect(() => {
    if (!isEmpty(values)) {
      setFormikValues(values);
    }

    if (!isEmpty(errors)) {
      setFormikErrors(errors);
    }
    if (!isEmpty(touched)) {
      setFormikTouched(touched);
    }
  }, [
    values,
    errors,
    touched,
    setFormikTouched,
    setFormikValues,
    setFormikErrors
  ]);

  useEffect(() => {
    if (isSuccess) {
      resetFormikState();
    }
  }, [isSuccess, resetFormikState]);

  return null;
};

export default PersistFormikState;
