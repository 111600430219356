import * as React from "react";
import Alert from "@material-ui/lab/Alert";
import { useDuplicateEmailsError } from "hooks/useIsDuplicateEmail";
export const DuplicateEmailError = ({ values }: { values: any }) => {
  const isDuplicateEmail = useDuplicateEmailsError();

  return isDuplicateEmail ? (
    <Alert severity="error">
      The same email address has been entered more than once, please change one
      of the email addresses
    </Alert>
  ) : null;
};
