import React from "react";
import { ErrorBoundary as ErrorBoundaryWrapper } from "react-error-boundary";

import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ErrorFallback = ({ componentStack, error }: any) => {
  return (
    <>
      <Typography gutterBottom>An Unexpected Error Occurred</Typography>
      <Alert severity="error">{error?.message}</Alert>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel-header"
        >
          <Typography>Error Details</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography color="error" variant="body2" component="p">
            {componentStack}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ErrorBoundaryWrapper FallbackComponent={ErrorFallback}>
    {children}
  </ErrorBoundaryWrapper>
);

ErrorBoundary.displayName = "ErrorBoundary";

export default ErrorBoundary;
