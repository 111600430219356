import { useState } from "react";
import { createContainer } from "unstated-next";

const useAppState = () => {
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [formikValues, setFormikValues] = useState<any>({});
  const [formikErrors, setFormikErrors] = useState<any>({});
  const [formikTouched, setFormikTouched] = useState<any>({});

  const resetFormikState = () => {
    setFormikTouched({});
    setFormikValues({});
    setFormikErrors({});
  };

  return {
    submissionSuccess,
    setSubmissionSuccess,
    formikValues,
    formikErrors,
    setFormikValues,
    setFormikErrors,
    formikTouched,
    setFormikTouched,
    resetFormikState
  };
};

const AppState = createContainer(useAppState);

export default AppState;
