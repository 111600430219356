import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const ReCAPTCHAWrapper: React.FC<any> = ({
  sitekey,
  onChange,
  isError,
  setFieldValue
}: any) => {
  const recaptchaRef = useRef<any>(null);

  useEffect(() => {
    if (isError) {
      recaptchaRef?.current !== null && recaptchaRef.current.reset();
      setFieldValue("recaptcha", "");
    }
  }, [isError, setFieldValue]);

  return (
    <ReCAPTCHA
      sitekey={sitekey}
      onChange={onChange}
      data-testid="recaptcha"
      ref={recaptchaRef}
    />
  );
};
