import * as React from "react";
import { useFormikContext } from "formik";

export const useDuplicateEmailsError = () => {
  const [isDuplicateEmail, setDuplicateEmail] = React.useState<boolean>(false);
  const { values }: any = useFormikContext() || {};

  React.useEffect(() => {
    const isDuplicate =
      (!!values.customerEmail &&
        !!values.personOneEmail &&
        values.customerEmail.toLowerCase() ===
          values.personOneEmail.toLowerCase()) ||
      (!!values.customerEmail &&
        !!values.personTwoEmail &&
        values.customerEmail.toLowerCase() ===
          values.personTwoEmail.toLowerCase()) ||
      (!!values.personOneEmail &&
        !!values.personTwoEmail &&
        values.personOneEmail.toLowerCase() ===
          values.personTwoEmail.toLowerCase());
    setDuplicateEmail(isDuplicate);
  }, [values.customerEmail, values.personOneEmail, values.personTwoEmail]);

  return isDuplicateEmail;
};
