import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

const questionsStyles = makeStyles(theme => ({
  questions: {
    padding: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: theme.spacing(4),
    fontWeight: 600,
    fontSize: "1.125rem"
  }
}));

const FAQs: React.FC = () => {
  const { questions } = questionsStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3">Frequently Asked Questions</Typography>
          <Accordion square>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>What is an Online colleague discount?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Your Colleague Discount card provides you with a discount in
                store. If you shop with us online you can sign up for a discount
                to be applied to your Morrisons.com shopping.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>How much discount do I get?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>15% with no cap on savings.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>How do I sign up for an online discount?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Go to My Morri &gt; My Pay &gt; Colleague Discount and click on
                Register for Online Discount. You will need to enter the email
                address you use to log in to Morrisons Groceries, your Employee
                ID and your Date of Birth.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>Where do I find my Employee ID?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Your employee ID can be found on MyMorri &gt; My Info &gt; My
                Personal Info.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>
                How do I add my other card holders to use online discount too?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                In addition to setting yourself up for an online colleague
                discount, you can set up one Friend/Family member to receive it
                too by entering the email address linked to their{" "}
                <Link
                  href="https://groceries.morrisons.com/webshop/startWebshop.do"
                  color="primary"
                >
                  Morrisons.com
                </Link>{" "}
                account. We are hoping to add a second Friend/Family member to
                the Online Discount System and will let you know when you are
                able to do this.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel5-content"
              id="panel5d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>
                How quickly will the discount be linked to my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Your discount will be linked to your account within 7 working
                days from the point of sign up, but normally earlier than that.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>How do I see my discount?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This depends on how your order is picked. If picked in store,
                then you will see the discount as you checkout online. If your
                order is picked at one of our central fulfillment centres, your
                discount will appear on your receipt when your order is
                delivered.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>Can I change my email address?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can change your email address and it will overwrite the
                one used previously.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>Can I swap my Friends and Family member?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, when you need to change details previously submitted then
                you simply submit a new email address for your Friend/Family
                member.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>What do I do if I receive an error code?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Please follow the link below the code and provide additional
                information to help investigate your issue further or click{" "}
                <Link
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeYzUOnZK3G8Q7ilAkGihMkD_it8GjaDt8Q7iRf-487pQ6OiA/viewform?gxids=7628"
                  color="primary"
                >
                  <b>here</b>
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>
                I've previously signed up for an online discount - do I need to
                do anything?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, this is not affected by the change to Colleague Discount
                cards
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>
                I've previously signed up for an online discount and it never
                worked. What's happening with my query?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The tech team are looking into these issues on a case by case
                basis and hope to have a resolution soon.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion square>
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={<ExpandMoreIcon color="secondary" />}
            >
              <Typography>
                How do I get a discount on Nutmeg and food boxes?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This is continuing through monthly codes which can be found on
                MyMorri.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Grid item xs={12} className={questions}>
            <Typography variant="h4">
              Still having problems? Please speak to your People Team in the
              first instance.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQs;
