import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "styles";
import Questions from "components/Questions";

const Home: React.FC = () => {
  const { list, link } = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">Terms &amp; Conditions</Typography>
          <ul className={list}>
            <li>
              Fraudulent use of the card is considered as gross misconduct.
            </li>
            <li>
              The Colleague Discount can be withdrawn from the colleague or
              nominated friend/family member at any time.
            </li>
            <li>
              The Colleague Discount Scheme is not a contractual entitlement and
              the Company reserves the right to review, adjust or discontinue
              the scheme and its rules at any time.
            </li>
          </ul>

          <Typography variant="body1">
            If you have an concerns around the use of your data please refer to
            our Colleague Privacy Policy{" "}
            <a
              className={link}
              href="https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home"
            >
              here
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Questions />
    </>
  );
};

export default Home;
