import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Form from "pages/Form";
import FAQs from "pages/FAQs";
import Terms from "pages/Terms";
import Success from "pages/Success";
import AppState from "containers/AppState";

const Routes: React.FC = () => {
  const { submissionSuccess } = AppState.useContainer();
  return (
    <Switch>
      <Route path="/faqs">
        <FAQs />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route path="/success">
        {submissionSuccess ? <Success /> : <Redirect to="/" />}
      </Route>
      <Route path="/">
        <Form />
      </Route>
    </Switch>
  );
};
export default Routes;
