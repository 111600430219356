import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import Header from "components/Header";
import { theme } from "styles";
import Container from "@material-ui/core/Container";
import ErrorBoundary from "components/ErrorBoundary";
import AppState from "containers/AppState";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppState.Provider>
        <Router>
          <CssBaseline />
          <Header />
          <Container maxWidth="md">
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Container>
        </Router>
      </AppState.Provider>
    </ThemeProvider>
  );
}

export default App;
