import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { AlertClassKey } from "@material-ui/lab/Alert";

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00683A"
    },
    secondary: {
      main: "#2B6DA3"
    },
    text: {
      primary: "#262626"
    },
    error: {
      main: "#d0021b"
    }
  },
  typography: {
    fontFamily: [
      "Morrisons-Agenda",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiAlert: {
      root: {
        margin: "1.5rem 0"
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.875rem"
      }
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "start"
      }
    },
    MuiCheckbox: {
      root: {
        marginTop: "-0.5rem"
      }
    }
  }
});
theme.typography.h1 = {
  fontSize: "1.6rem",
  fontWeight: 600,
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem"
  }
};
theme.typography.h2 = {
  fontSize: "1.25rem",
  fontWeight: 400,
  marginBottom: "1rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem"
  }
};
theme.typography.h3 = {
  fontSize: "1.25rem",
  fontWeight: 700,
  marginBottom: "1rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem"
  }
};
theme.typography.h4 = {
  fontSize: "1.125rem",
  fontWeight: 600,
  marginBottom: "0.5rem"
};
theme.typography.body1 = {
  fontSize: "1.25rem",
  fontWeight: 400,
  marginBottom: "1rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem"
  }
};

const useStyles = makeStyles(theme => ({
  list: {
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    margin: 0,
    fontSize: "1.125rem",
    "& li": {
      marginBottom: theme.spacing(1)
    }
  },
  field: {
    paddingBottom: "0.4rem"
  },
  tooltip: {
    marginLeft: "auto",
    marginRight: 0,
    display: "block",
    fontSize: "1.4rem"
  },
  button: {
    textTransform: "capitalize",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    marginBottom: "1.4rem"
  },
  link: {
    textDecoration: "underline",
    color: theme.palette.primary.dark
  }
}));

export default useStyles;
