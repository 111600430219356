import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useMutation } from "react-query";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoSharp";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import LoaderButton from "components/LoaderButton";
import { register } from "firebase/callables";
import { FormModel, MutateModel } from "types";
import Questions from "components/Questions";
import { withStyles, Theme } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialLink from "@material-ui/core/Link";
import AppState from "containers/AppState";
import PersistFormikState from "components/PersistFormikState";
import useStyles from "styles";
import { makeStyles } from "@material-ui/core/styles";
import { ReCAPTCHAWrapper } from "./ReCAPTCHAWrapper";
import { DuplicateEmailError } from "components/DuplicateEmailError";

import { MaintenanceModal } from "components/MaintenanceModal";
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY ?? "";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#262626",
    maxWidth: 224,
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 4px 6px 0px rgba(0,0,0,0.25)",
    borderRadius: "3px",
  },
}))(Tooltip);

const toolTipStyles = makeStyles((theme) => ({
  toolTipTitle: {
    fontWeight: 600,
  },
  toolTipLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "#2B6DA3",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    display: "block",
  },
}));

const validationSchema = Yup.object({
  employeeID: Yup.string()
    .matches(/^\d+$/, "Employee ID must be a number")
    .min(8, "Minimum 8 characters")
    .max(9, "Maximum 9 characters")
    .required("Employee ID required"),
  customerEmail: Yup.string()
    .email("Invalid email address")
    .required("Email address required"),
  personOneEmail: Yup.string().email("Invalid email address"),
  recaptcha: Yup.string().required("Recaptcha required"),
});

const ColleagueForm: React.FC = () => {
  const { setSubmissionSuccess } = AppState.useContainer();
  const history = useHistory();
  const [confirmTerms, setConfirm] = useState<boolean>(false);
  const { button, field, tooltip, link } = useStyles();
  const { toolTipTitle, toolTipLine } = toolTipStyles();
  const initialValues: FormModel = {
    customerEmail: "",
    employeeID: "",
    personOneEmail: undefined,
    recaptcha: "",
  };

  const submitForm = async (formValues: FormModel) => {
    try {
      return await register(formValues);
    } catch (error) {
      throw error;
    }
  };

  const [
    mutate,
    { isLoading, isError, isSuccess, error },
  ]: MutateModel = useMutation(submitForm);

  const handleSubmit = (values: FormModel) => {
    mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
      setSubmissionSuccess(true);

      history.push("/success");
    }
  }, [isSuccess, history, setSubmissionSuccess]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, isValid, setFieldValue, dirty }) => (
          <Form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Your details
                </Typography>
                <Typography gutterBottom>
                  Please submit the email address that you use to log into your
                  online groceries (customer) account
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  className={field}
                  required
                  label="Your customer email"
                  name="customerEmail"
                  fullWidth
                  variant="outlined"
                  autoComplete="chrome-off"
                  as={TextField}
                  error={touched.customerEmail && Boolean(errors.customerEmail)}
                  helperText={touched.customerEmail && errors.customerEmail}
                  data-testid="customer-email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  className={field}
                  required
                  label="Employee ID"
                  fullWidth
                  variant="outlined"
                  autoComplete="chrome-off"
                  name="employeeID"
                  as={TextField}
                  error={touched.employeeID && Boolean(errors.employeeID)}
                  helperText={touched.employeeID && errors.employeeID}
                  data-testid="employee-id"
                />
                <HtmlTooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  leaveTouchDelay={3000}
                  enterTouchDelay={0}
                  className={tooltip}
                  title={
                    <>
                      <span className={toolTipTitle}>{"Employee ID"}</span>
                      <span className={toolTipLine}></span>
                      {
                        "You can find this by logging into MyMorri and clicking on the navigation next to your photo"
                      }
                    </>
                  }
                >
                  <InfoIcon color="secondary" />
                </HtmlTooltip>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Add your friends and family
                </Typography>
                <Typography gutterBottom>
                  You can add 1 friend or family member to also receive your
                  colleague discount when shopping online.
                </Typography>
                <Typography gutterBottom>
                  Please use the email address they use to log in to Morrisons
                  Groceries.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  className={field}
                  label="Email"
                  fullWidth
                  variant="outlined"
                  autoComplete="chrome-off"
                  name="personOneEmail"
                  as={TextField}
                  error={
                    touched.personOneEmail && Boolean(errors.personOneEmail)
                  }
                  helperText={touched.personOneEmail && errors.personOneEmail}
                  data-testid="person1-email"
                />
                <HtmlTooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  leaveTouchDelay={3000}
                  enterTouchDelay={0}
                  className={tooltip}
                  title={
                    <React.Fragment>
                      <span className={toolTipTitle}>
                        {"Adding a friend or Family member"}
                      </span>
                      <span className={toolTipLine}></span>
                      {
                        "Make sure you add the email that is connected to their Active online shopping account before signing up to colleague discount."
                      }
                    </React.Fragment>
                  }
                >
                  <InfoIcon color="secondary" />
                </HtmlTooltip>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmTerms}
                      onChange={() => setConfirm(!confirmTerms)}
                      color="primary"
                      data-testid="confirm-checkbox"
                    />
                  }
                  label={
                    <>
                      I understand that the Online Colleague Discount is subject
                      to the Terms and Conditions of Use for Colleague Discount
                      which can be found{" "}
                      <Link className={link} to="/terms">
                        here
                      </Link>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <ReCAPTCHAWrapper
                  sitekey={recaptchaKey}
                  onChange={(recaptcha: any) =>
                    setFieldValue("recaptcha", recaptcha)
                  }
                  isError={isError}
                  setFieldValue={setFieldValue}
                />
              </Grid>

              <Grid item xs={12}>
                {isError ? (
                  <Alert severity="error" data-testid="error-alert">
                    {!!error?.message && error?.message !== "INTERNAL"
                      ? `There was an error registering the user. ${error?.message}`
                      : `Oops - there might not be an online groceries account
                    associated to one of the emails you entered, please check
                    details you have provided. Alternatively if your details are
                    correct please try again.`}
                    {error?.details?.code ? (
                      <>
                        <br />
                        <br />
                      </>
                    ) : null}
                    {error?.details?.code?.startsWith("CD")
                      ? "Error code: "
                      : null}
                    <strong>{error?.details?.code}</strong>
                    {error?.details?.code?.startsWith("CD") ? (
                      <p>
                        <MaterialLink
                          target="_blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeYzUOnZK3G8Q7ilAkGihMkD_it8GjaDt8Q7iRf-487pQ6OiA/viewform?gxids=7628"
                        >
                          <b>Please complete this form to report the error</b>
                        </MaterialLink>
                      </p>
                    ) : null}
                  </Alert>
                ) : null}
                <DuplicateEmailError values={values} />
                <LoaderButton
                  className={button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={!(isValid && dirty) || isLoading || !confirmTerms}
                  data-testid="submit-btn"
                  isLoading={isLoading}
                >
                  Submit
                </LoaderButton>
              </Grid>
            </Grid>
            <Questions />
            <PersistFormikState isSuccess={isSuccess} />
          </Form>
        )}
      </Formik>
      <MaintenanceModal />
    </>
  );
};

export default ColleagueForm;
